import React from 'react';
import './App.scss';
import {BrowserRouter, Link, NavLink, Outlet, Route, Routes} from "react-router-dom";
import {Overview} from "./overview/Overview";
import {Downloads} from "./downloads/Downloads";
import {Docs} from "./docs/Docs";
import {Gsavi} from "./gsavi/Gsavi";
import {EventType, PublicClientApplication} from "@azure/msal-browser";
import {b2cPolicies, msalConfig} from "./authConfig";
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate} from "@azure/msal-react";
import {Login} from "./login/Login";
import {log} from "util";

function MainLayout({logout}) {
    return (
        <>
            <header>
                <img alt="logo" src="logo.png" />
            </header>
            <div className="content-container">
                <nav>
                    <div className="top-nav">
                        <NavLink className={({ isActive }) => isActive ? 'active' : ''} end to="/">Overview</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/gsavi">G-SAVI Installations</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/downloads">Downloads</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/docs">Docs</NavLink>
                    </div>
                    <div className="bottom-nav">
                        <a onClick={() => logout()}>Logout</a>
                    </div>

                </nav>
                <div className="outlet">
                    <Outlet />
                </div>

            </div>
        </>
    )
}

// @ts-ignore
class App extends React.Component<{ msalInstance: any }> {
    componentDidMount() {
        this.props.msalInstance.addEventCallback((message:any) => {
            if (message.eventType === EventType.LOGIN_FAILURE && message.error.errorMessage.includes("AADB2C90118")) {
                // The user has forgotten their password.
                this.props.msalInstance.loginRedirect(b2cPolicies.authorities.forgotPassword);
            }
        });

    }

    render() {
        let {msalInstance} = this.props;
        return (
            <MsalProvider instance={msalInstance}>
                <AuthenticatedTemplate>
                    <BrowserRouter>
                        <Routes>
                            <Route element={<MainLayout logout={() => msalInstance.logout()} />}>
                                <Route index path="/" element={<Overview/>}/>
                                <Route path="gsavi" element={<Gsavi/>}/>
                                <Route path="downloads" element={<Downloads/>}/>
                                <Route path="docs" element={<Docs/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Login msalInstance={msalInstance}/>
                </UnauthenticatedTemplate>
            </MsalProvider>
        );
    }
}

export default App;
