import React from "react";
import ReactMapboxGl, {Layer, Feature, GeoJSONLayer, Marker, ScaleControl, ZoomControl} from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './OverviewMap.scss';
import {MsalContext, useMsal} from "@azure/msal-react";

const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN || '',
    dragRotate: false,
    dragPan: true
});

interface OverviewMapProps {
    vessels: Vessel[];
    currentCenter: [number, number];
}

interface OverviewMapState {
    minBounds: [number, number],
    maxBounds: [number, number],
    zoomLevel: [number],
}

export class Vessel {
    constructor(public readonly name: string, public readonly position: [number, number], public heading: number) {
    }
}

export class OverviewMap extends React.Component<OverviewMapProps, OverviewMapState> {
    static contextType = MsalContext;

    constructor(props) {
        super(props);
        this.state = {
            zoomLevel: [2],
            minBounds: [0, 0],
            maxBounds: [0, 0]
        }
    }

    render() {
        let filteredVessels = [];

        if (this.state?.maxBounds) {
            filteredVessels = this.props.vessels.filter(x => x.position[0] > this.state.minBounds[0] && x.position[1] > this.state.minBounds[1] &&
                x.position[0] < this.state.maxBounds[0] && x.position[1] < this.state.maxBounds[1]);
        }

        return (
            <>
                <div className="map-container">
                    <Map
                        style="mapbox://styles/sdaveyseakit/cl977ag2n001215qw7tb0spgy"
                        containerStyle={{
                            height: '100%',
                            width: '100%',
                        }}
                        zoom={this.state.zoomLevel}
                        onStyleLoad={(map) => this.onStyleLoaded(map)}
                        onRender={(map) => this.updateBounds(map)}
                        center={this.props.currentCenter}
                    >
                        <ZoomControl />
                        <ScaleControl className="scale-control"/>

                        <>
                            {filteredVessels.map(x =>
                                (<Marker key={x.name} coordinates={x.position}>
                                    <img height={50} className="vessel-map-img" src="top-down.png"
                                         alt="Maxlimer vessel"
                                         style={{transform: 'rotate(' + (x.heading - 90) + 'deg)'}}/>
                                </Marker>)
                            )}
                        </>


                    </Map>
                </div>
            </>

        )
    }

    private onStyleLoaded(map) {
        map.touchZoomRotate.disableRotation();
    }

    private updateBounds(map) {
        const bounds = map.getBounds();
        this.setState({
            minBounds: [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
            maxBounds: [bounds.getNorthEast().lng, bounds.getNorthEast().lat]
        });
    }
}

