import React from "react";
import "./Login.scss";

interface LoginProps {
    msalInstance: any;
}

export class Login extends React.Component<LoginProps, {}> {
    render() {
        return (<>
            <div className="outer-box">
                <div className="info-box">
                    <img alt="logo" src="logo.png" />

                    <button className="pure-button" onClick={() => this.props.msalInstance.loginRedirect()}>Login</button>
                </div>
            </div>
        </>)
    }
}
