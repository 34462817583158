import React from "react";
import {api} from "../Utils";

export class Downloads extends React.Component {

    download(name: string) {
        api('document/token')
            .then(res => res.json())
            .then((x: {token: string}) => {
            const url = `${process.env.REACT_APP_HOST}/document/download?name=${name}&token=${x.token}`;
            window.open(url, '_blank').focus();
        })
    }

    render() {
        return (
            <section id="downloads">
                <h2>Downloads</h2>
                <a href="#" onClick={() => this.download('TechnicalManual6-2.pdf')}>Technical Manual 6-2</a>
            </section>
        )
    }
}
