import React from "react";
import {OverviewMap, Vessel} from "./OverviewMap";
import {api} from "../Utils";

interface OverviewState {
    data: VesselsResponse;
    currentCenter: [number, number];
}

interface VesselsResponse {
    vessels: VesselResponse[];
}

interface VesselResponse {
    name: string;
    telemetry: TelemetryResponse[];
}

interface TelemetryResponse {
    latitude: number;
    longitude: number;
    heading: number;
}

export class Overview extends React.Component<{}, OverviewState> {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                vessels: []
            },
            currentCenter: [0,0]
        };
    }

    componentDidMount() {
        api('vessel').then(x => x.json())
            .then((x: VesselsResponse) => {
                this.setState({
                    data: x
                })
            });
    }

    render() {
        return (<>
            <section id="overview">
                <div className="key">
                    <table className="pure-table pure-table-bordered">
                        <thead>
                        <tr>
                            <th>Vessels</th>
                        </tr>
                        </thead>

                        <tbody>
                        {this.state.data.vessels.map(x =>
                            <tr key={x.name}>
                                <td><a className="pure-menu-link" href="#" onClick={() => this.vesselClicked(x)}>{x.name}</a></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

                <OverviewMap vessels={this.state.data.vessels
                    .filter(x => x.telemetry.length > 0)
                    .map(x => new Vessel(x.name, [x.telemetry.at(0)?.longitude ?? 0, x.telemetry.at(0)?.latitude ?? 0], x.telemetry.at(0)?.heading ?? 0))}
                currentCenter={this.state.currentCenter}/>
            </section>

        </>)
    }

    private vesselClicked(x: VesselResponse) {
        const latestPos = x.telemetry.at(0);
        this.setState({
            currentCenter: [latestPos?.longitude ?? 0, latestPos?.latitude ?? 0]
        });
    }
}
