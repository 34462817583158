import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {PublicClientApplication} from "@azure/msal-browser";
import {loginRequest, msalConfig} from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

export const acquireAccessToken = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        console.error('OH NO');
    }

    const request = {
        scopes: loginRequest.scopes,
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken
};

ReactDOM.render(
    <React.StrictMode>
        <App msalInstance={msalInstance}/>
    </React.StrictMode>,
    document.getElementById('root')
);
