import React from "react";
import "./Docs.scss";

export class Docs extends React.Component {
    render() {
        return (
            <iframe src={process.env.REACT_APP_DOCS_URL}></iframe>
        )
    }
}
