import React from "react";
import {api} from "../Utils";
import "./Gsavi.scss";

interface AuditState {
    instances: Instance[];
}

interface Instance {
    instanceName: string;
    versionNumber: string;
}

export class Gsavi extends React.Component<{}, AuditState> {

    componentDidMount() {
        api('softwareaudit')
            .then(res => res.json())
            .then(x => {
                this.setState({
                    instances: x.audits
                });
            });
    }

    render() {
        return (
            <section id="gsavi">
            <div>
                <h2>Installations</h2>
                <div className="instances">
                    <table className="pure-table pure-table-bordered">
                        <thead>
                        <tr>
                            <th>Instance Name</th>
                            <th>Version Number</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state?.instances && this.state.instances.map(x =>
                            <tr key={x.instanceName}>
                                <td>{x.instanceName}</td>
                                <td>{x.versionNumber}</td>
                            </tr>
                        )}

                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <h2>How to Update G-SAVI</h2>

                <ol>
                    <li>Note down the current version of G-SAVI you are running. This is important in case you need to perform a roll back if any issues occur.</li>
                    <li>Locate GSAVI-Installer.exe in C:/GSAVI and open it.</li>
                    <li>Paste in the following key <code>JY47uxGgRR77m1xDAnR30BLw1122InrziWeAojCBP2o</code>
                        <br />
                        <img src="install.jpg" alt="Example install" />
                    </li>
                    <li>Click 'Install/Update G-SAVI' and the update will run.</li>
                    <li>Once this is completed open G-SAVI as normal with the launcher</li>
                </ol>


                <h3>Performing a Rollback</h3>

                <ol>
                    <li>Open GSAVI-Installer.exe in C:/GSAVI</li>
                    <li>In the text field labelled 'Version' replace 'latest' with the specific version of G-SAVI you would like. eg. 22.257.2 as shown in the image below
                    <br />
                        <img src="rollback.jpg" alt="Rollback example" />
                    </li>
                    <li>Update G-SAVI and open it as usual and you should be on the version you specified.</li>
                </ol>

            </div>
            </section>
        )
    }
}
